/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-18 21:05:11
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-18 21:06:33
 */
import CustomTable from './CustomTable.vue'
export default CustomTable
