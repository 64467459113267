/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-18 20:58:19
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-18 21:01:42
 */
import Custom from './Custom.vue'
export default Custom
