/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-18 20:58:12
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-18 21:06:55
 */
import CustomTable from './CustomTable'
export default {
  name: 'AdminCustom',
  components: {
    CustomTable
  }
}
